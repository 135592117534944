.main-section {
    display: flex;
    justify-content: space-between;
    /* flex-direction: column; */
    align-items: center;
    background: #f2f2f2 ;
}

.left-side {
    width: 100%;
    padding: 20px;
}

.right-side {
    width: 50%;
    padding: 20px;
}

.logo-marquee-slider {
    width: 100%;
}
.logo-image {
    margin: 0 10px; /* Adjust the margin as per your requirement */
}



.left-section-content {
    font-size: 16px;
}
.left-section-content p {
 color: #333;
}
.image-marquee-slider {
    width: 100%;
    background-color: #fff; /* White background */
}

.logo-marquee-slider img {
    width: 100px; /* Adjust as needed */
    height: auto;
    /* margin: 0 20px;  */
    background: white;
    padding: 15px;
}


@media (max-width: 768px) {
    .left-section-content {
        font-size: 14px;
    }
    .main-section {
        flex-direction: column;
    }
    .left-side{
        width: 100%;
        padding: 5px;
    }
    .right-side{
        width: 100%;
        padding: 5px;
    }
}

@media (max-width: 576px) {
    .left-section-content {
        font-size: 12px;
    }
}
