.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* General Body Styles */


/* Main Container */
.privacy-policy-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e5e5; */
}

/* Header */
.privacy-policy-container h1 {
  font-size: 2rem;
  /* color: rgb(213, 157, 35); */
  margin-bottom: 20px;
  border-bottom: 2px solid rgb(213, 157, 35);
  padding-bottom: 10px;
}

/* Section Headings */
.privacy-policy-container h2,
.privacy-policy-container h3 {
  font-size: 1.5rem;
  color: #444;
  margin-top: 20px;
  margin-bottom: 10px;
  border-left: 4px solid rgb(213, 157, 35);
  padding-left: 10px;
}

/* Paragraphs */
.privacy-policy-container p {
  font-size: 1rem;
  margin: 10px 0;
  line-height: 1.8;
}

/* List Styles */
.privacy-policy-container ul {
  margin: 15px 0 15px 20px;
  padding: 0;
  list-style: disc;
}

.privacy-policy-container ul li {
  margin-bottom: 10px;
  line-height: 1.6;
}

/* Links */
.privacy-policy-container a {
  color: rgb(213, 157, 35);
  text-decoration: none;
  font-weight: bold;
}

.privacy-policy-container a:hover {
  text-decoration: underline;
}

/* Highlight Important Text */
.privacy-policy-container strong {
  font-weight: bold;
}

.privacy-policy-container em {
  font-style: italic;
}

/* Table for Structured Data (Optional) */
.privacy-policy-container table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  margin-bottom: 15px;
}

.privacy-policy-container table th,
.privacy-policy-container table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.privacy-policy-container table th {
  background-color: rgb(213, 157, 35);
  color: #fff;
  font-weight: bold;
}

.privacy-policy-container table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Footer */
.privacy-policy-container .contact-info {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.privacy-policy-container .contact-info h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #444;
}

.privacy-policy-container .contact-info p {
  margin: 5px 0;
  line-height: 1.6;
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
  .privacy-policy-container {
      padding: 15px;
  }

  .privacy-policy-container h1 {
      font-size: 1.75rem;
  }

  .privacy-policy-container h2,
  .privacy-policy-container h3 {
      font-size: 1.25rem;
  }
}


/* Example CSS to change Tawk.to widget color */
.tawk-widget .tawk-button {
  background-color: rgb(213, 157, 35) !important;
}
