html.ltie9 body,
body {
	min-width: 1140px;
}
.container {
	max-width: 1140px;
}
.limoking-caption-wrapper .limoking-caption-inner {
	max-width: 1140px;
}
.body-wrapper.limoking-boxed-style {
	max-width: 1220px;
	overflow: hidden;
}
.body-wrapper.limoking-boxed-style .limoking-header-wrapper {
	max-width: 1220px;
	margin: 0px auto;
}
.limoking-logo-inner {
	max-width: 115px;
}
.limoking-logo {
	margin-top: 50px;
}
.limoking-logo {
	margin-bottom: 45px;
}
.limoking-logo-right-text {
	margin-top: 42px;
}
.limoking-navigation-wrapper {
	margin-top: 35px;
}
.limoking-navigation-wrapper .limoking-main-menu > li > a {
	padding-bottom: 45px;
}
.top-social-wrapper .social-icon a {
	color: #ffffff;
}
h1,
h2,
h3,
h4,
h5,
h6,
.limoking-title-font {
	font-family: "Montserrat";
}
body,
input,
textarea,
select,
.limoking-content-font,
.limoking-car-contact-form .wpcf7 input[type="submit"] {
	font-family: "Hind";
}
.limoking-info-font,
.limoking-plain .about-us-caption,
.limoking-normal .about-us-caption,
.limoking-button,
input[type="button"],
input[type="submit"] {
	font-family: "Merriweather";
}
.limoking-testimonial-item.modern-style .testimonial-item-inner:before {
	font-family: "Mystery Quest";
}
.limoking-navigation {
	font-family: "Hind";
}
.limoking-slider-item {
	font-family: "Montserrat";
}
body {
	font-size: 14px;
}
h1 {
	font-size: 47px;
}
h2 {
	font-size: 41px;
}
h3 {
	font-size: 32px;
}
h4 {
	font-size: 26px;
}
h5 {
	font-size: 21px;
}
h6 {
	font-size: 16px;
}
.limoking-navigation-wrapper .limoking-main-menu > li > a {
	font-size: 13px;
}
.top-navigation-wrapper {
	background-color: #171717;
}
.top-navigation-wrapper {
	color: #8e8e8e;
}
.top-navigation-wrapper a {
	color: #f2f2f2;
}
.limoking-header-inner,
.limoking-header-wrapper.header-style-3-wrapper,
.limoking-header-wrapper.header-style-2-wrapper {
	background-color: #232323;
}
.limoking-header-wrapper.header-style-5-wrapper .limoking-header-inner {
	background-color: rgba(35, 35, 35, 0.75);
}
@media only screen and (max-width: 1100px) {
	.limoking-header-wrapper.header-style-5-wrapper .limoking-header-inner {
		background-color: #232323;
	}
}
.limoking-main-menu > li > a,
.limoking-cart-item-count {
	color: #a2a2a2;
}
.limoking-header-wrapper.header-style-3-wrapper .limoking-header-inner,
.limoking-header-wrapper.header-style-2-wrapper .limoking-header-inner {
	background-color: #232323;
	background-color: #232323;
}
.limoking-main-menu > li:hover > a,
.limoking-main-menu > li.current-menu-item > a,
.limoking-main-menu > li.current-menu-ancestor > a {
	color: #ffffff;
	opacity: 1;
	filter: alpha(opacity=100);
}
/* #limoking-navigation-gimmick{ border-bottom-color: #D59D23; } */
.limoking-top-woocommerce-inner,
.limoking-menu-search {
	background: #2b2b2b;
	background: rgba(43, 43, 43, 0.8);
}
.limoking-top-woocommerce,
.limoking-top-woocommerce-inner > a,
.limoking-menu-search input[type="text"] {
	color: #ffffff;
}
.limoking-main-menu > .limoking-normal-menu .sub-menu,
.limoking-main-menu > .limoking-mega-menu .sf-mega {
	border-top-color: #1c1c1c;
}
.limoking-main-menu > .limoking-normal-menu li,
.limoking-main-menu > .limoking-mega-menu .sf-mega {
	background-color: #2e2e2e;
}
.limoking-main-menu > li > .sub-menu a,
.limoking-main-menu > li > .sf-mega a {
	color: #bebebe;
}
.limoking-main-menu > li > .sub-menu a:hover,
.limoking-main-menu > li > .sub-menu .current-menu-item > a,
.limoking-main-menu > li > .sub-menu .current-menu-ancestor > a,
.limoking-main-menu > li > .sf-mega a:hover,
.limoking-main-menu > li > .sf-mega .current-menu-item > a,
.limoking-main-menu > li > .sf-mega .current-menu-ancestor > a {
	color: #ffffff;
}
.limoking-main-menu .limoking-normal-menu li > a.sf-with-ul:after {
	border-left-color: #ffffff;
}
.limoking-main-menu .sf-mega-section-inner > ul > li > a:hover,
.limoking-main-menu .sf-mega-section-inner > ul > li.current-menu-item > a {
	background-color: #2a2a2a;
}
.limoking-main-menu > li > .sub-menu *,
.limoking-main-menu > li > .sf-mega * {
	border-color: #373737;
}
.limoking-main-menu > li > .sf-mega .sf-mega-section-inner > a {
	color: #ffffff;
}
.limoking-main-menu > li > .sf-mega .sf-mega-section-inner > a:hover,
.limoking-main-menu
	> li
	> .sf-mega
	.sf-mega-section-inner.current-menu-item
	> a,
.limoking-main-menu
	> li
	> .sf-mega
	.sf-mega-section-inner.current-menu-ancestor
	> a {
	color: #ffffff;
}
#limoking-responsive-navigation.dl-menuwrapper button {
	background-color: #3a3a3a;
}
#limoking-responsive-navigation.dl-menuwrapper button:hover,
#limoking-responsive-navigation.dl-menuwrapper button.dl-active,
#limoking-responsive-navigation.dl-menuwrapper ul {
	background-color: #303030;
}
body {
	background-color: #dddddd;
}
.body-wrapper,
.limoking-single-lightbox-container {
	background-color: #ffffff;
}
.limoking-page-title,
.limoking-page-title-gimmick {
	color: #ffffff;
}
.limoking-page-caption {
	color: #a6dde8;
}
h1,
h2,
h3,
h4,
h5,
h6,
.limoking-title,
.limoking-title a {
	color: #454545;
}
.limoking-item-title-wrapper .limoking-item-title {
	color: #292929;
	border-color: #292929;
}
.limoking-item-title-divider {
	border-color: #333333;
}
.limoking-item-title-wrapper .limoking-item-caption {
	color: #9b9b9b;
}
body {
	color: #808080;
}
a {
	color: #d59d23;
}
a:hover {
	color: #6db7c6;
}
body * {
	border-color: #dddddd;
}
.page-not-found-block {
	background-color: #d65938;
}
.page-not-found-block {
	color: #ffffff;
}
.page-not-found-search .gdl-search-form input[type="text"] {
	background-color: #963a20;
}
.page-not-found-search .gdl-search-form input[type="text"] {
	color: #d57f5c;
}
.limoking-sidebar .limoking-widget-title {
	color: #383838;
}
.limoking-sidebar * {
	border-color: #eeeeee;
}
.limoking-sidebar ul li:before {
	border-color: #bdbdbd;
}
.gdl-search-form input {
	background-color: #f7f7f7;
}
.gdl-search-form input {
	color: #9d9d9d;
}
.gdl-search-form input {
	border-color: #ebebeb;
}
.tagcloud a {
	background-color: #d59d23;
}
.tagcloud a,
.tagcloud a:hover {
	color: #ffffff;
}
.about-us-title {
	color: #3a3a3a;
}
.about-us-caption {
	color: #9f9f9f;
}
.about-us-title-divider {
	border-color: #d59d23;
}
.limoking-accordion-item.style-1 .pre-active .accordion-title {
	color: #3c3c3c;
}
.limoking-accordion-item.style-1 .accordion-title {
	color: #8d8d8d;
}
.limoking-accordion-item.style-1 .accordion-title i {
	background-color: #f3f3f3;
}
.limoking-accordion-item.style-1 .accordion-title i {
	color: #a8a8a8;
}
.limoking-accordion-item.style-1 .accordion-title i.icon-minus {
	background-color: #d59d23;
}
.limoking-accordion-item.style-1 .accordion-title i.icon-minus {
	color: #ffffff;
}
.limoking-banner-item-wrapper .flex-direction-nav .flex-prev,
.limoking-banner-item-wrapper .flex-direction-nav .flex-next {
	color: #999999;
}
.limoking-box-with-icon-item {
	background-color: #f5f5f5;
}
.limoking-box-with-icon-item > i,
.limoking-box-with-icon-item .box-with-icon-title {
	color: #585858;
}
.limoking-box-with-icon-item {
	color: #929292;
}
.limoking-car-button,
.limoking-car-button:hover,
.limoking-button,
.limoking-button:hover,
input[type="button"],
input[type="submit"],
.limoking-top-menu > .limoking-mega-menu .sf-mega a.limoking-button,
.single-book-now,
.single-book-now:hover {
	color: #ffffff;
}
.limoking-car-button,
.limoking-button,
input[type="button"],
input[type="submit"],
.single-book-now {
	background-color: #d59d23;
}
.limoking-border-button {
	border-color: #d59d23;
	color: #d59d23;
}
.limoking-button {
	border-color: #e6e6e6;
}
.column-service-title {
	color: #444444;
}
.column-service-content {
	color: #909090;
}
.column-service-icon i {
	color: #d59d23;
}
.limoking-column-service-item.limoking-type-2 .column-service-icon {
	border-color: #d59d23;
}
.list-with-icon .list-with-icon-title {
	color: #333333;
}
.list-with-icon .list-with-icon-icon {
	border-color: #333333;
	color: #333333;
}
.limoking-menu-title,
.limoking-menu-icon,
.limoking-menu-price {
	color: #d59d23;
}
.limoking-menu-ingredients-caption {
	color: #818181;
}
.limoking-list-menu-gimmick {
	border-color: #000000;
	color: #000000;
}
.limoking-pie-chart-item .pie-chart-title {
	color: #313131;
}
.limoking-price-inner-item {
	background-color: #f9f9f9;
}
.limoking-price-item .price-title-wrapper {
	background-color: #454545;
}
.limoking-price-item .price-title {
	color: #ffffff;
}
.limoking-price-item .price-tag {
	background-color: #838383;
}
.limoking-price-item .best-price .price-tag {
	background-color: #d59d23;
}
.limoking-price-item .price-tag {
	color: #ffffff;
}
.limoking-process-tab .limoking-process-icon {
	background-color: #f3f3f3;
}
.limoking-process-tab .limoking-process-icon {
	border-color: #e3e3e3;
}
.limoking-process-tab .limoking-process-icon i {
	color: #aaaaaa;
}
.limoking-process-tab .process-line .process-line-divider {
	border-color: #e3e3e3;
}
.limoking-process-tab .process-line .icon-chevron-down,
.limoking-process-tab .process-line .icon-chevron-right {
	color: #e3e3e3;
}
.limoking-process-wrapper .limoking-process-tab .limoking-process-title {
	color: #454545;
}
.limoking-skill-item-wrapper {
	color: #3a3a3a;
}
.stunning-text-title,
.stunning-item-title {
	color: #414141;
}
.stunning-text-caption,
.stunning-item-caption {
	color: #949494;
}
.tab-title-wrapper .tab-title {
	background-color: #f9f9f9;
}
.tab-title-wrapper .tab-title {
	color: #3b3b3b;
}
.tab-title-wrapper .tab-title.active,
.tab-content-wrapper {
	background-color: #ffffff;
}
table tr th {
	background-color: #d59d23;
}
table tr th {
	color: #ffffff;
}
table.style-2 tr:nth-child(odd) {
	background-color: #f9f9f9;
}
table.style-2 tr:nth-child(odd) {
	color: #949494;
}
table.style-2 tr:nth-child(even) {
	background-color: #f3f3f3;
}
table.style-2 tr:nth-child(even) {
	color: #949494;
}
.limoking-blog-title,
.limoking-blog-title a {
	color: #474747;
}
.limoking-blog-title a:hover {
	color: #d59d23;
}
.blog-info,
.blog-info a,
.blog-info i {
	color: #929292;
}
.limoking-blog-thumbnail .limoking-sticky-banner {
	background-color: #232323;
}
.limoking-blog-thumbnail .limoking-sticky-banner,
.limoking-blog-thumbnail .limoking-sticky-banner i {
	color: #ffffff;
}
.limoking-social-share,
.limoking-social-share a {
	background-color: #f3f3f3;
}
.limoking-social-share .social-share-title {
	color: #353535;
}
.limoking-standard-style .limoking-single-blog-tag a {
	background-color: #d59d23;
}
.limoking-standard-style .limoking-single-blog-tag a {
	color: #ffffff;
}
.format-aside .limoking-blog-content {
	background-color: #d59d23;
}
.format-aside .limoking-blog-content {
	color: #ffffff;
}
.format-quote .limoking-top-quote blockquote {
	color: #8d8d8d;
}
.format-quote .limoking-quote-author {
	color: #d59d23;
}
.limoking-single-nav > div i {
	background-color: #f3f3f3;
}
.limoking-single-nav > div i {
	color: #8d8d8d;
}
.car-title a,
.limoking-rate-table-column.limoking-col-1 .rate-table-car-title a {
	color: #545454;
}
.car-title a:hover {
	color: #d59d23;
}
.single-car .limoking-car-info {
	background-color: #f5f5f5;
}
.single-car .limoking-car-info {
	color: #737373;
}
.limoking-classic-car .car-rate-info-price,
.single-car .car-rate-info-price {
	color: #d59d23;
}
.limoking-classic-car .car-rate-info-amount,
.single-car .car-rate-info-amount {
	color: #5c5c5c;
}
.single-car .car-rate-info-caption {
	color: #9c9c9c;
}
.limoking-rate-table-head-wrapper .limoking-rate-table-column-inner {
	background: #232323;
}
.limoking-rate-table-head-wrapper .rate-table-ribbon:after {
	border-top-color: #232323;
}
.limoking-rate-table-head-wrapper .rate-table-title {
	color: #ffffff;
}
.limoking-rate-table-head-wrapper .rate-table-caption {
	color: #b2b2b2;
}
.limoking-rate-table-head-wrapper
	.limoking-feature
	.limoking-rate-table-column-inner,
.limoking-rate-table-column .rate-table-book-now {
	background: #d59d23;
}
.limoking-rate-table-head-wrapper .limoking-feature .rate-table-ribbon:after {
	border-top-color: #d59d23;
}
.limoking-rate-table-head-wrapper .limoking-feature .rate-table-title,
.limoking-rate-table-head-wrapper .limoking-feature .rate-table-caption,
.limoking-rate-table-column .rate-table-book-now {
	color: #fff;
}
.limoking-rate-table-column .limoking-rate-column {
	background: #f5f5f5;
}
.limoking-rate-table-content-wrapper .rate-table-price {
	color: #d59d23;
}
.limoking-rate-table-content-wrapper .rate-table-price-text {
	color: #232323;
}
.limoking-rate-table-content-wrapper .rate-table-price-caption,
.limoking-rate-table-content-wrapper .rate-table-price.rate-table-price-none {
	color: #9c9c9c;
}
.portfolio-item-filter a,
.car-item-filter a {
	color: #aeaeae;
}
.portfolio-item-filter a.active,
.car-item-filter a.active {
	color: #d59d23;
}
.limoking-image-link-shortcode .limoking-image-link-overlay,
.portfolio-thumbnail .portfolio-overlay,
.car-thumbnail .car-overlay {
	background-color: #ffffff;
}
.portfolio-thumbnail .portfolio-overlay-icon .portfolio-icon,
.car-thumbnail .car-overlay-icon .car-icon {
	background-color: #d59d23;
}
.portfolio-title a {
	color: #545454;
}
.portfolio-title a:hover {
	color: #d59d23;
}
.portfolio-info,
.portfolio-info a {
	color: #a2a2a2;
}
.limoking-pagination .page-numbers,
.woocommerce nav.woocommerce-pagination ul li a,
.woocommerce nav.woocommerce-pagination ul li span {
	background-color: #ebebeb;
}
.limoking-pagination .page-numbers,
.woocommerce nav.woocommerce-pagination ul li a,
.woocommerce nav.woocommerce-pagination ul li span {
	color: #979797;
}
.limoking-pagination .page-numbers:hover,
.limoking-pagination .page-numbers.current,
.woocommerce nav.woocommerce-pagination ul li a:hover,
.woocommerce nav.woocommerce-pagination ul li span.current {
	background-color: #d59d23;
}
.limoking-pagination .page-numbers:hover,
.limoking-pagination .page-numbers.current,
.woocommerce nav.woocommerce-pagination ul li a:hover,
.woocommerce nav.woocommerce-pagination ul li span.current {
	color: #ffffff;
}
.limoking-personnel-item.modern-style .personnel-info {
	background-color: #d59d23;
}
.limoking-personnel-item.modern-style .personnel-author,
.limoking-personnel-item.modern-style .personnel-position {
	color: #ffffff;
}
.limoking-personnel-item .personnel-item-inner {
	background-color: #f5f5f5;
}
.limoking-personnel-item.round-style .personnel-item {
	background-color: #d59d23;
}
.limoking-personnel-item .personnel-author {
	color: #3b3b3b;
}
.limoking-personnel-item .personnel-author-image {
	border-color: #d59d23;
}
.limoking-personnel-item .personnel-position {
	color: #acacac;
}
.limoking-personnel-item .personnel-content {
	color: #959595;
}
.limoking-personnel-item .personnel-social i {
	color: #3b3b3b;
}
.limoking-testimonial-item .testimonial-item-inner,
.limoking-testimonial-item .testimonial-author-image {
	background-color: #f5f5f5;
}
.limoking-testimonial-item .testimonial-content {
	color: #9b9b9b;
}
.limoking-testimonial-item .testimonial-author {
	color: #d59d23;
}
.limoking-testimonial-item .testimonial-position {
	color: #4d4d4d;
}
.limoking-testimonial-item.modern-style .testimonial-item-inner:before {
	color: #d59d23;
}
.limoking-testimonial-item .testimonial-author-image {
	border-color: #d59d23;
}
.limoking-testimonial-item.box-style .testimonial-item-inner:after {
	border-top-color: #dddddd;
	border-left-color: #dddddd;
}
.limoking-gallery-thumbnail .gallery-item {
	background-color: #e5e5e5;
}
.limoking-gallery-thumbnail-container .gallery-caption {
	background-color: #000000;
}
.limoking-gallery-thumbnail-container .gallery-caption {
	color: #ffffff;
}
.nivo-controlNav a,
.flex-control-paging li a {
	background-color: #ffffff;
}
.nivo-controlNav a:hover,
.nivo-controlNav a.active,
.flex-control-paging li a:hover,
.flex-control-paging li a.flex-active {
	background-color: #5e5e5e;
}
.nivo-controlNav a,
.flex-control-paging li a {
	border-color: #5e5e5e !important;
}
.nivo-directionNav a,
.flex-direction-nav a,
.ls-flawless .ls-nav-prev,
.ls-flawless .ls-nav-next {
	background-color: #000000;
}
body .nivo-directionNav a,
body .flex-direction-nav a,
body .flex-direction-nav a:hover,
.ls-flawless .ls-nav-prev,
.ls-flawless .ls-nav-next {
	color: #ffffff;
}
.limoking-caption {
	background-color: #000000;
}
.limoking-caption-title {
	color: #ffffff;
}
.limoking-caption-text {
	color: #ffffff;
}
.limoking-caption-wrapper.post-slider {
	background-color: #000000;
}
.limoking-caption-wrapper.post-slider .limoking-caption-title {
	color: #ffffff;
}
.limoking-caption-wrapper.post-slider .limoking-caption-text {
	color: #dddddd;
}
.limoking-post-slider-item.style-no-excerpt
	.limoking-caption-wrapper
	.limoking-caption-date,
.limoking-post-slider-item.style-no-excerpt
	.limoking-caption-wrapper
	.limoking-title-link {
	color: #ffffff;
}
.limoking-post-slider-item.style-no-excerpt
	.limoking-caption-wrapper
	.limoking-caption-date,
.limoking-post-slider-item.style-no-excerpt
	.limoking-caption-wrapper
	.limoking-title-link {
	background-color: #d59d23;
}
.limoking-item-title-wrapper .limoking-flex-prev,
.limoking-item-title-wrapper .limoking-flex-next {
	color: #6d6d6d;
}
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
	background-color: #f5f5f5;
}
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
	color: #b5b5b5;
}
input::input-placeholder {
	color: #b5b5b5;
}
input::-webkit-input-placeholder {
	color: #b5b5b5;
}
input::-moz-placeholder {
	color: #b5b5b5;
}
input:-moz-placeholder {
	color: #b5b5b5;
}
input:-ms-input-placeholder {
	color: #b5b5b5;
}
textarea::input-placeholder {
	color: #b5b5b5;
}
textarea::-webkit-input-placeholder {
	color: #b5b5b5;
}
textarea::-moz-placeholder {
	color: #b5b5b5;
}
textarea:-moz-placeholder {
	color: #b5b5b5;
}
textarea:-ms-input-placeholder {
	color: #b5b5b5;
}
.footer-wrapper {
	background-color: #252525;
}
.footer-wrapper .limoking-widget-title,
.footer-wrapper .limoking-widget-title a {
	color: #dddddd;
}
.footer-wrapper {
	color: #bfbfbf;
}
.footer-wrapper a {
	color: #7f7f7f;
}
.footer-wrapper a:hover {
	color: #cecece;
}
.footer-wrapper * {
	border-color: #363636;
}
.footer-wrapper input[type="text"],
.footer-wrapper input[type="email"],
.footer-wrapper input[type="password"],
.footer-wrapper textarea {
	background-color: #141414;
}
.footer-wrapper input[type="text"],
.footer-wrapper input[type="email"],
.footer-wrapper input[type="password"],
.footer-wrapper textarea {
	color: #828282;
}
.footer-wrapper input[type="text"],
.footer-wrapper input[type="email"],
.footer-wrapper input[type="password"],
.footer-wrapper textarea {
	border-color: #313131;
}
.footer-wrapper .limoking-button,
.footer-wrapper .limoking-button:hover,
.footer-wrapper input[type="button"],
.footer-wrapper input[type="submit"] {
	color: #ffffff;
}
.footer-wrapper .limoking-button,
.footer-wrapper input[type="button"],
.footer-wrapper input[type="submit"] {
	background-color: #d59d23;
}
.footer-wrapper .tagcloud a {
	background-color: #d59d23;
}
.footer-wrapper .tagcloud a,
.footer-wrapper .tagcloud a:hover {
	color: #ffffff;
}
.copyright-wrapper {
	background-color: #0f0f0f;
}
.copyright-wrapper {
	color: #6b6b6b;
}
.footer-wrapper .copyright-wrapper {
	border-color: #0f0f0f;
}
html .woocommerce span.onsale,
html .woocommerce-page span.onsale,
html .woocommerce-message,
html .woocommerce div.product .woocommerce-tabs ul.tabs li.active,
html .woocommerce #content div.product .woocommerce-tabs ul.tabs li.active,
html .woocommerce-page div.product .woocommerce-tabs ul.tabs li.active,
html
	.woocommerce-page
	#content
	div.product
	.woocommerce-tabs
	ul.tabs
	li.active {
	background: #d59d23;
}
html .woocommerce .star-rating,
html .woocommerce-page .star-rating,
html .woocommerce .star-rating:before,
html .woocommerce-page .star-rating:before,
html .woocommerce div.product span.price,
html .woocommerce div.product p.price,
html .woocommerce #content div.product span.price,
html .woocommerce #content div.product p.price,
html .woocommerce-page div.product span.price,
html .woocommerce-page div.product p.price,
html .woocommerce-page #content div.product span.price,
html .woocommerce-page #content div.product p.price {
	color: #d59d23;
}
html .woocommerce-message a.button,
html .woocommerce-error a.button,
html .woocommerce-info a.button,
html .woocommerce-message,
html .woocommerce-error,
html .woocommerce-info,
html .woocommerce span.onsale,
html .woocommerce-page span.onsale,
html .woocommerce div.product .woocommerce-tabs ul.tabs li.active,
html .woocommerce #content div.product .woocommerce-tabs ul.tabs li.active,
html .woocommerce-page div.product .woocommerce-tabs ul.tabs li.active,
html .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li.active,
html .woocommerce nav.woocommerce-pagination ul li span.current,
html .woocommerce-page nav.woocommerce-pagination ul li span.current,
html .woocommercenav.woocommerce-pagination ul li a:hover,
html .woocommerce-page nav.woocommerce-pagination ul li a:hover {
	color: #ffffff;
}
html .woocommerce-info {
	background: #389ec5;
}
html .woocommerce-error {
	background: #c23030;
}
html .woocommerce a.button.alt:hover,
html .woocommerce button.button.alt:hover,
html .woocommerce input.button.alt:hover,
html .woocommerce #respond input#submit.alt:hover,
html .woocommerce #content input.button.alt:hover,
html .woocommerce-page a.button.alt:hover,
html .woocommerce-page button.button.alt:hover,
html .woocommerce-page input.button.alt:hover,
html .woocommerce-page #respond input#submit.alt:hover,
html .woocommerce-page #content input.button.alt:hover,
html .woocommerce a.button.alt,
html .woocommerce button.button.alt,
html .woocommerce input.button.alt,
html .woocommerce #respond input#submit.alt,
html .woocommerce #content input.button.alt,
html .woocommerce-page a.button.alt,
html .woocommerce-page button.button.alt,
html .woocommerce-page input.button.alt,
html .woocommerce-page #respond input#submit.alt,
html .woocommerce-page #content input.button.alt,
html .woocommerce a.button,
html .woocommerce button.button,
html .woocommerce input.button,
html .woocommerce #respond input#submit,
html .woocommerce #content input.button,
html .woocommerce-page a.button,
html .woocommerce-page button.button,
html .woocommerce-page input.button,
html .woocommerce-page #respond input#submit,
html .woocommerce-page #content input.button,
html .woocommerce a.button:hover,
html .woocommerce button.button:hover,
html .woocommerce input.button:hover,
html .woocommerce #respond input#submit:hover,
html .woocommerce #content input.button:hover,
html .woocommerce-page a.button:hover,
html .woocommerce-page button.button:hover,
html .woocommerce-page input.button:hover,
html .woocommerce-page #respond input#submit:hover,
html .woocommerce-page #content input.button:hover,
html .woocommerce ul.products li.product a.loading,
html .woocommerce div.product form.cart .button,
html .woocommerce #content div.product form.cart .button,
html .woocommerce-page div.product form.cart .button,
html .woocommerce-page #content div.product form.cart .button {
	background: #d59d23;
}
html .woocommerce a.button.alt:hover,
html .woocommerce button.button.alt:hover,
html .woocommerce input.button.alt:hover,
html .woocommerce #respond input#submit.alt:hover,
html .woocommerce #content input.button.alt:hover,
html .woocommerce-page a.button.alt:hover,
html .woocommerce-page button.button.alt:hover,
html .woocommerce-page input.button.alt:hover,
html .woocommerce-page #respond input#submit.alt:hover,
html .woocommerce-page #content input.button.alt:hover,
html .woocommerce a.button.alt,
html .woocommerce button.button.alt,
html .woocommerce input.button.alt,
html .woocommerce #respond input#submit.alt,
html .woocommerce #content input.button.alt,
html .woocommerce-page a.button.alt,
html .woocommerce-page button.button.alt,
html .woocommerce-page input.button.alt,
html .woocommerce-page #respond input#submit.alt,
html .woocommerce-page #content input.button.alt,
html .woocommerce a.button,
html .woocommerce button.button,
html .woocommerce input.button,
html .woocommerce #respond input#submit,
html .woocommerce #content input.button,
html .woocommerce-page a.button,
html .woocommerce-page button.button,
html .woocommerce-page input.button,
html .woocommerce-page #respond input#submit,
html .woocommerce-page #content input.button,
html .woocommerce a.button:hover,
html .woocommerce button.button:hover,
html .woocommerce input.button:hover,
html .woocommerce #respond input#submit:hover,
html .woocommerce #content input.button:hover,
html .woocommerce-page a.button:hover,
html .woocommerce-page button.button:hover,
html .woocommerce-page input.button:hover,
html .woocommerce-page #respond input#submit:hover,
html .woocommerce-page #content input.button:hover,
html .woocommerce ul.products li.product a.loading,
html .woocommerce div.product form.cart .button,
html .woocommerce #content div.product form.cart .button,
html .woocommerce-page div.product form.cart .button,
html .woocommerce-page #content div.product form.cart .button {
	color: #ffffff;
}
html .woocommerce a.button.alt:hover,
html .woocommerce button.button.alt:hover,
html .woocommerce input.button.alt:hover,
html .woocommerce #respond input#submit.alt:hover,
html .woocommerce #content input.button.alt:hover,
html .woocommerce-page a.button.alt:hover,
html .woocommerce-page button.button.alt:hover,
html .woocommerce-page input.button.alt:hover,
html .woocommerce-page #respond input#submit.alt:hover,
html .woocommerce-page #content input.button.alt:hover,
html .woocommerce a.button.alt,
html .woocommerce button.button.alt,
html .woocommerce input.button.alt,
html .woocommerce #respond input#submit.alt,
html .woocommerce #content input.button.alt,
html .woocommerce-page a.button.alt,
html .woocommerce-page button.button.alt,
html .woocommerce-page input.button.alt,
html .woocommerce-page #respond input#submit.alt,
html .woocommerce-page #content input.button.alt,
html .woocommerce a.button,
html .woocommerce button.button,
html .woocommerce input.button,
html .woocommerce #respond input#submit,
html .woocommerce #content input.button,
html .woocommerce-page a.button,
html .woocommerce-page button.button,
html .woocommerce-page input.button,
html .woocommerce-page #respond input#submit,
html .woocommerce-page #content input.button,
html .woocommerce a.button:hover,
html .woocommerce button.button:hover,
html .woocommerce input.button:hover,
html .woocommerce #respond input#submit:hover,
html .woocommerce #content input.button:hover,
html .woocommerce-page a.button:hover,
html .woocommerce-page button.button:hover,
html .woocommerce-page input.button:hover,
html .woocommerce-page #respond input#submit:hover,
html .woocommerce-page #content input.button:hover,
html .woocommerce ul.products li.product a.loading,
html .woocommerce div.product form.cart .button,
html .woocommerce #content div.product form.cart .button,
html .woocommerce-page div.product form.cart .button,
html .woocommerce-page #content div.product form.cart .button {
	border-bottom: 3px solid #1aa3b2;
}
html .woocommerce #reviews #comments ol.commentlist li img.avatar,
html .woocommerce-page #reviews #comments ol.commentlist li img.avatar {
	background: #ebebeb;
}
html .woocommerce #reviews #comments ol.commentlist li img.avatar,
html .woocommerce-page #reviews #comments ol.commentlist li img.avatar,
html .woocommerce #reviews #comments ol.commentlist li .comment-text,
html .woocommerce-page #reviews #comments ol.commentlist li .comment-text,
html .woocommerce ul.products li.product a img,
html .woocommerce-page ul.products li.product a img,
html .woocommerce ul.products li.product a img:hover,
html .woocommerce-page ul.products li.product a img:hover,
html .woocommerce-page div.product div.images img,
html .woocommerce-page #content div.product div.images img,
html .woocommerce form.login,
html .woocommerce form.checkout_coupon,
html .woocommerce form.register,
html .woocommerce-page form.login,
html .woocommerce-page form.checkout_coupon,
html .woocommerce-page form.register,
html .woocommerce table.cart td.actions .coupon .input-text,
html .woocommerce #content table.cart td.actions .coupon .input-text,
html .woocommerce-page table.cart td.actions .coupon .input-text,
html .woocommerce-page #content table.cart td.actions .coupon .input-text {
	border: 1px solid #ebebeb;
}
html .woocommerce div.product .woocommerce-tabs ul.tabs:before,
html .woocommerce #content div.product .woocommerce-tabs ul.tabs:before,
html .woocommerce-page div.product .woocommerce-tabs ul.tabs:before,
html .woocommerce-page #content div.product .woocommerce-tabs ul.tabs:before,
html .woocommerce table.shop_table tfoot td,
html .woocommerce table.shop_table tfoot th,
html .woocommerce-page table.shop_table tfoot td,
html .woocommerce-page table.shop_table tfoot th,
html .woocommerce table.shop_table tfoot td,
html .woocommerce table.shop_table tfoot th,
html .woocommerce-page table.shop_table tfoot td,
html .woocommerce-page table.shop_table tfoot th {
	border-bottom: 1px solid #ebebeb;
}
html .woocommerce .cart-collaterals .cart_totals table tr:first-child th,
html .woocommerce .cart-collaterals .cart_totals table tr:first-child td,
html .woocommerce-page .cart-collaterals .cart_totals table tr:first-child th,
html .woocommerce-page .cart-collaterals .cart_totals table tr:first-child td {
	border-top: 3px #ebebeb solid;
}
html .woocommerce .cart-collaterals .cart_totals tr td,
html .woocommerce .cart-collaterals .cart_totals tr th,
html .woocommerce-page .cart-collaterals .cart_totals tr td,
html .woocommerce-page .cart-collaterals .cart_totals tr th {
	border-bottom: 2px solid #ebebeb;
}
html .woocommerce div.product .woocommerce-tabs ul.tabs li,
html .woocommerce #content div.product .woocommerce-tabs ul.tabs li,
html .woocommerce-page div.product .woocommerce-tabs ul.tabs li,
html .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li,
html .woocommerce table.cart a.remove,
html .woocommerce #content table.cart a.remove,
html .woocommerce-page table.cart a.remove,
html .woocommerce-page #content table.cart a.remove,
html .woocommerce #payment,
html .woocommerce-page #payment,
html .woocommerce .customer_details,
html .woocommerce ul.order_details,
html .woocommerce nav.woocommerce-pagination ul li a,
html .woocommerce-page nav.woocommerce-pagination ul li a,
html .woocommerce form .form-row input.input-text,
html .woocommerce form .form-row textarea,
html .woocommerce-page form .form-row input.input-text,
html .woocommerce-page form .form-row textarea,
html .woocommerce .quantity input.qty,
html .woocommerce #content .quantity input.qty,
html .woocommerce-page .quantity input.qty,
html .woocommerce-page #content .quantity input.qty,
html .woocommerce .widget_shopping_cart .total,
html .woocommerce-page .widget_shopping_cart .total {
	background: #f3f3f3;
}
html .woocommerce .quantity input.qty,
html .woocommerce #content .quantity input.qty,
html .woocommerce-page .quantity input.qty,
html .woocommerce-page #content .quantity input.qty {
	border: 1px solid #f3f3f3;
}
html .woocommerce .widget_shopping_cart .total,
html .woocommerce-page .widget_shopping_cart .total {
	border-top: 2px solid #e5e5e5;
}
html .woocommerce table.cart a.remove:hover,
html .woocommerce #content table.cart a.remove:hover,
html .woocommerce-page table.cart a.remove:hover,
html .woocommerce-page #content table.cart a.remove:hover,
html #payment div.payment_box,
html .woocommerce-page #payment div.payment_box {
	background: #e5e5e5;
}
html .woocommerce table.shop_table tfoot td,
html .woocommerce table.shop_table tfoot th,
html .woocommerce-page table.shop_table tfoot td,
html .woocommerce-page table.shop_table tfoot th,
.cart-subtotal th,
.shipping th,
.total th,
html .woocommerce table.shop_attributes .alt th,
html .woocommerce-page table.shop_attributes .alt th,
html .woocommerce ul.products li.product .price,
html.woocommerce-page ul.products li.product .price {
	color: #333333;
}
html .woocommerce ul.products li.product .price del,
html .woocommerce-page ul.products li.product .price del,
html .woocommerce table.cart a.remove,
html .woocommerce #content table.cart a.remove,
html .woocommerce-page table.cart a.remove,
html .woocommerce-page #content table.cart a.remove {
	color: #888888;
}
html .woocommerce .quantity .plus,
html .woocommerce .quantity .minus,
html .woocommerce #content .quantity .plus,
html .woocommerce #content .quantity .minus,
html .woocommerce-page .quantity .plus,
html .woocommerce-page .quantity .minus,
html .woocommerce-page #content .quantity .plus,
html .woocommerce-page #content .quantity .minus {
	border: 1px solid #a0a0a0;
}
html .woocommerce .quantity .plus,
html .woocommerce .quantity .minus,
html .woocommerce #content .quantity .plus,
html .woocommerce #content .quantity .minus,
html .woocommerce-page .quantity .plus,
html .woocommerce-page .quantity .minus,
html .woocommerce-page #content .quantity .plus,
html .woocommerce-page #content .quantity .minus {
	color: #ffffff;
}
html .woocommerce .quantity .plus,
html .woocommerce #content .quantity .plus,
html .woocommerce-page .quantity .plus,
html .woocommerce-page #content .quantity .plus,
html .woocommerce .quantity .plus:hover,
html .woocommerce #content .quantity .plus:hover,
html .woocommerce-page .quantity .plus:hover,
html .woocommerce-page #content .quantity .plus:hover {
	background: #9a9a9a;
}
html .woocommerce .quantity .minus,
html .woocommerce #content .quantity .minus,
html .woocommerce-page .quantity .minus,
html .woocommerce-page #content .quantity .minus,
html .woocommerce .quantity .minus:hover,
html .woocommerce #content .quantity .minus:hover,
html .woocommerce-page .quantity .minus:hover,
html .woocommerce-page #content .quantity .minus:hover {
	background: #b6b6b6;
}
.half-container {
	max-width: 570px;
}
.limoking-skin-dark-skin,
.limoking-skin-dark-skin .limoking-skin-content {
	color: #ffffff;
}
.limoking-skin-dark-skin i,
.limoking-skin-dark-skin .limoking-flex-prev,
.limoking-skin-dark-skin .limoking-flex-next {
	color: #ffffff;
}
.limoking-skin-dark-skin h1,
.limoking-skin-dark-skin h2,
.limoking-skin-dark-skin h3,
.limoking-skin-dark-skin h4,
.limoking-skin-dark-skin h5,
.limoking-skin-dark-skin h6,
.limoking-skin-dark-skin .limoking-skin-title,
.limoking-skin-dark-skin .limoking-skin-title a {
	color: #ffffff;
}
.limoking-skin-dark-skin .limoking-skin-title a:hover {
	color: #ffffff;
}
.limoking-skin-dark-skin .limoking-skin-info,
.limoking-skin-dark-skin .limoking-skin-info a,
.limoking-skin-dark-skin .limoking-skin-info a:hover {
	color: #ffffff;
}
.limoking-skin-dark-skin a,
.limoking-skin-dark-skin .limoking-skin-link,
.limoking-skin-dark-skin .limoking-skin-link-color {
	color: #d59d23;
}
.limoking-skin-dark-skin a:hover,
.limoking-skin-dark-skin .limoking-skin-link:hover {
	color: #ffffff;
}
.limoking-skin-dark-skin .limoking-skin-box,
.limoking-skin-dark-skin .limoking-column-service-item .limoking-skin-box {
	background-color: #d59d23;
}
.limoking-skin-dark-skin *,
.limoking-skin-dark-skin .limoking-skin-border {
	border-color: #ffffff;
}
.limoking-skin-dark-skin .limoking-button,
.limoking-skin-dark-skin .limoking-button:hover,
.limoking-skin-dark-skin input[type="button"],
.limoking-skin-dark-skin input[type="submit"] {
	color: #ffffff;
	background-color: #d59d23;
}
.limoking-skin-dark-testimonial,
.limoking-skin-dark-testimonial .limoking-skin-content {
	color: #ffffff;
}
.limoking-skin-dark-testimonial i,
.limoking-skin-dark-testimonial .limoking-flex-prev,
.limoking-skin-dark-testimonial .limoking-flex-next {
	color: #ffffff;
}
.limoking-skin-dark-testimonial h1,
.limoking-skin-dark-testimonial h2,
.limoking-skin-dark-testimonial h3,
.limoking-skin-dark-testimonial h4,
.limoking-skin-dark-testimonial h5,
.limoking-skin-dark-testimonial h6,
.limoking-skin-dark-testimonial .limoking-skin-title,
.limoking-skin-dark-testimonial .limoking-skin-title a {
	color: #ffffff;
}
.limoking-skin-dark-testimonial .limoking-skin-title a:hover {
	color: #ffffff;
}
.limoking-skin-dark-testimonial .limoking-skin-info,
.limoking-skin-dark-testimonial .limoking-skin-info a,
.limoking-skin-dark-testimonial .limoking-skin-info a:hover {
	color: #ffffff;
}
.limoking-skin-dark-testimonial a,
.limoking-skin-dark-testimonial .limoking-skin-link,
.limoking-skin-dark-testimonial .limoking-skin-link-color {
	color: #eb4c4c;
}
.limoking-skin-dark-testimonial a:hover,
.limoking-skin-dark-testimonial .limoking-skin-link:hover {
	color: #ffffff;
}
.limoking-skin-dark-testimonial .limoking-skin-box,
.limoking-skin-dark-testimonial
	.limoking-column-service-item
	.limoking-skin-box {
	background-color: #2b2b2b;
}
.limoking-skin-dark-testimonial *,
.limoking-skin-dark-testimonial .limoking-skin-border {
	border-color: #ffffff;
}
.limoking-skin-dark-testimonial .limoking-button,
.limoking-skin-dark-testimonial .limoking-button:hover,
.limoking-skin-dark-testimonial input[type="button"],
.limoking-skin-dark-testimonial input[type="submit"] {
	color: #ffffff;
	background-color: #ffffff;
}
.limoking-skin-light-grey,
.limoking-skin-light-grey .limoking-skin-content {
	color: #999999;
}
.limoking-skin-light-grey i,
.limoking-skin-light-grey .limoking-flex-prev,
.limoking-skin-light-grey .limoking-flex-next {
	color: #e0e0e0;
}
.limoking-skin-light-grey h1,
.limoking-skin-light-grey h2,
.limoking-skin-light-grey h3,
.limoking-skin-light-grey h4,
.limoking-skin-light-grey h5,
.limoking-skin-light-grey h6,
.limoking-skin-light-grey .limoking-skin-title,
.limoking-skin-light-grey .limoking-skin-title a {
	color: #eb4c4c;
}
.limoking-skin-light-grey .limoking-skin-title a:hover {
	color: #eb4c4c;
}
.limoking-skin-light-grey .limoking-skin-info,
.limoking-skin-light-grey .limoking-skin-info a,
.limoking-skin-light-grey .limoking-skin-info a:hover {
	color: #828282;
}
.limoking-skin-light-grey a,
.limoking-skin-light-grey .limoking-skin-link,
.limoking-skin-light-grey .limoking-skin-link-color {
	color: #282828;
}
.limoking-skin-light-grey a:hover,
.limoking-skin-light-grey .limoking-skin-link:hover {
	color: #848484;
}
.limoking-skin-light-grey .limoking-skin-box,
.limoking-skin-light-grey .limoking-column-service-item .limoking-skin-box {
	background-color: #a5a5a5;
}
.limoking-skin-light-grey *,
.limoking-skin-light-grey .limoking-skin-border {
	border-color: #eaeaea;
}
.limoking-skin-light-grey .limoking-button,
.limoking-skin-light-grey .limoking-button:hover,
.limoking-skin-light-grey input[type="button"],
.limoking-skin-light-grey input[type="submit"] {
	color: #a3a3a3;
	background-color: #f2f2f2;
}
.limoking-skin-darkblue,
.limoking-skin-darkblue .limoking-skin-content {
	color: #c9c9c9;
}
.limoking-skin-darkblue i,
.limoking-skin-darkblue .limoking-flex-prev,
.limoking-skin-darkblue .limoking-flex-next {
	color: #d59d23;
}
.limoking-skin-darkblue h1,
.limoking-skin-darkblue h2,
.limoking-skin-darkblue h3,
.limoking-skin-darkblue h4,
.limoking-skin-darkblue h5,
.limoking-skin-darkblue h6,
.limoking-skin-darkblue .limoking-skin-title,
.limoking-skin-darkblue .limoking-skin-title a {
	color: #ffffff;
}
.limoking-skin-darkblue .limoking-skin-title a:hover {
	color: #ffffff;
}
.limoking-skin-darkblue .limoking-skin-info,
.limoking-skin-darkblue .limoking-skin-info a,
.limoking-skin-darkblue .limoking-skin-info a:hover {
	color: #ffffff;
}
.limoking-skin-darkblue a,
.limoking-skin-darkblue .limoking-skin-link,
.limoking-skin-darkblue .limoking-skin-link-color {
	color: #d59d23;
}
.limoking-skin-darkblue a:hover,
.limoking-skin-darkblue .limoking-skin-link:hover {
	color: #ffffff;
}
.limoking-skin-darkblue .limoking-skin-box,
.limoking-skin-darkblue .limoking-column-service-item .limoking-skin-box {
	background-color: #d59d23;
}
.limoking-skin-darkblue *,
.limoking-skin-darkblue .limoking-skin-border {
	border-color: #d59d23;
}
.limoking-skin-darkblue .limoking-button,
.limoking-skin-darkblue .limoking-button:hover,
.limoking-skin-darkblue input[type="button"],
.limoking-skin-darkblue input[type="submit"] {
	color: #ffffff;
	background-color: #d59d23;
}
.limoking-skin-about-us,
.limoking-skin-about-us .limoking-skin-content {
	color: #757575;
}
.limoking-skin-about-us i,
.limoking-skin-about-us .limoking-flex-prev,
.limoking-skin-about-us .limoking-flex-next {
	color: #d59d23;
}
.limoking-skin-about-us h1,
.limoking-skin-about-us h2,
.limoking-skin-about-us h3,
.limoking-skin-about-us h4,
.limoking-skin-about-us h5,
.limoking-skin-about-us h6,
.limoking-skin-about-us .limoking-skin-title,
.limoking-skin-about-us .limoking-skin-title a {
	color: #4d4d4d;
}
.limoking-skin-about-us .limoking-skin-title a:hover {
	color: #4d4d4d;
}
.limoking-skin-about-us .limoking-skin-info,
.limoking-skin-about-us .limoking-skin-info a,
.limoking-skin-about-us .limoking-skin-info a:hover {
	color: #9e9c9c;
}
.limoking-skin-about-us a,
.limoking-skin-about-us .limoking-skin-link,
.limoking-skin-about-us .limoking-skin-link-color {
	color: #d59d23;
}
.limoking-skin-about-us a:hover,
.limoking-skin-about-us .limoking-skin-link:hover {
	color: #4bb2c6;
}
.limoking-skin-about-us .limoking-skin-box,
.limoking-skin-about-us .limoking-column-service-item .limoking-skin-box {
	background-color: #4d4d4d;
}
.limoking-skin-about-us *,
.limoking-skin-about-us .limoking-skin-border {
	border-color: #d59d23;
}
.limoking-skin-about-us .limoking-button,
.limoking-skin-about-us .limoking-button:hover,
.limoking-skin-about-us input[type="button"],
.limoking-skin-about-us input[type="submit"] {
	color: #ffffff;
	background-color: #d59d23;
}
.limoking-header-wrapper {
	position: relative;
}
.limoking-rate-table-mobile-wrapper .limoking-rate-table-column.limoking-col-1,
.limoking-rate-table-mobile-wrapper .limoking-rate-table-column.limoking-col-2 {
	width: 50%;
}
