
.slick-dots {
	bottom: 30px !important; /* Adjust the position of dots */
}

.slick-dots li button:before {
	color: gray !important;
	border: 2px solid white;
	font-size: 1px !important;
	border-radius: 100%;
	background: gray;
	width: 16px !important;
	height: 16px !important;
	/* Change dot color to red */
}
.slick-next {
	color: white !important;
	right: 30px !important;
	z-index: 100;
}
.slick-prev {
	color: white !important;
	left: 30px !important;
	z-index: 100;
}
.slick-slide img {
	width: 100% !important;
}

.locations-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* More flexible min size */
	gap: 20px;
	padding: 20px;
	margin-top: 20px;
}

/* Ensuring the cards don’t break the layout */
.location-card {
	position: relative;
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
	transition: transform 0.3s ease-in-out;
	text-decoration: none;
}

/* Hover effect remains */
.location-card:hover {
	transform: scale(1.05);
}

/* Make images responsive */
.location-image {
	width: 100%;
	height: auto; /* Allow images to scale naturally */
	max-height: 200px; /* Prevent overly large images */
	object-fit: cover;
}

/* Overlay effect */
.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Text styles */
.location-name {
	color: white;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	padding: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
	.locations-container {
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Smaller cards for mobile */
		padding: 10px;
	}

	.location-name {
		font-size: 16px;
	}
}

@media (max-width: 480px) {
	.locations-container {
		grid-template-columns: 1fr; /* Single column for small screens */
	}

	.location-image {
		height: 150px; /* Adjust image height */
	}
}
