html body {
	min-width: 300px;
}

#limoking-responsive-navigation {
	display: none;
}

/*--- tablet ---*/

@media only screen and (max-width: 1100px) {
	body .header-style-5-wrapper .limoking-logo {
		margin-top: 20px;
		margin-bottom: 20px;
		float: none;
		text-align: center;
	}
	body .header-style-5-wrapper .limoking-logo-inner {
		margin: 0px auto;
	}
	body .header-style-5-wrapper .limoking-navigation {
		display: inline-block;
		text-align: left;
		line-height: 1.7;
	}
	body
		.header-style-5-wrapper
		.limoking-navigation-wrapper
		.limoking-main-menu
		> li
		> a {
		padding-bottom: 20px;
	}
	body .limoking-header-wrapper.header-style-5-wrapper .limoking-header-inner {
		padding-top: 1px;
	}
	body
		.limoking-header-wrapper.header-style-5-wrapper
		.limoking-logo-right-wrapper {
		float: none;
	}
	body
		.limoking-header-wrapper.header-style-5-wrapper
		.limoking-logo-right-text {
		text-align: center;
		margin-top: 0px;
	}
	body
		.limoking-header-wrapper.header-style-5-wrapper
		.limoking-logo-right-wrapper
		.limoking-navigation-wrapper {
		float: none;
		text-align: center;
		line-height: 0;
	}
	body
		.limoking-header-wrapper.header-style-5-wrapper
		#limoking-navigation-gimmick {
		margin-top: -15px;
	}
	.limoking-header-wrapper.header-style-5-wrapper {
		position: relative;
	}
}

@media only screen and (max-width: 959px) {
	body .container {
		max-width: 768px;
	}
	body .body-wrapper.limoking-boxed-style {
		max-width: 808px;
	}

	.limoking-hide-in-tablet {
		display: none;
	}
	.limoking-hide-in-tablet-mobile {
		display: none;
	}

	.flex-direction-nav .flex-prev,
	.flex-direction-nav .flex-next {
		opacity: 1;
	}
	.limoking-fixed-header {
		display: none;
	}

	.gdlr-icon-shortcode {
		margin-bottom: 10px;
	}

	.single-car .limoking-car-info-wrapper {
		width: 300px;
	}

	.limoking-rate-table-column .limoking-rate-column {
		margin-right: 1px;
	}
	.limoking-rate-table-column .limoking-rate-button {
		margin-right: 1px;
	}

	#limoking-menu-search-button {
		display: none;
	}
	.header-inner-header-style-2
		.limoking-navigation-wrapper
		.limoking-main-menu
		> li
		> a {
		padding-left: 30px;
	}
	.header-inner-header-style-2 .sf-menu .limoking-normal-menu > ul {
		left: 7px;
	}
	.header-inner-header-style-3
		.limoking-navigation-wrapper
		.limoking-main-menu
		> li
		> a {
		padding-left: 30px;
	}
	.header-inner-header-style-3 .sf-menu .limoking-normal-menu > ul {
		left: 7px;
	}
}

/*--- mobile landscape style ---*/
@media only screen and (max-width: 767px) {
	body .container {
		max-width: 420px;
	}
	body .body-wrapper.limoking-boxed-style {
		max-width: 460px;
	}
	body .body-wrapper {
		overflow: hidden;
	}

	.one-ninth,
	.one-eighth,
	.one-seventh,
	.one-fifth,
	.two-fifth,
	.three-fifth,
	.four-fifth,
	.one,
	.two,
	.three,
	.four,
	.five,
	.six,
	.seven,
	.eight,
	.nine,
	.ten,
	.eleven,
	.twelve {
		width: 100%;
	}

	.limoking-hide-in-tablet {
		display: block;
	}
	.limoking-hide-in-mobile {
		display: none;
	}

	.limoking-service-half-background-item .limoking-half-left,
	.limoking-service-half-background-item .limoking-half-right {
		float: none;
		width: auto;
		padding: 70px 0px;
		margin: 0px;
	}
	.limoking-service-half-background-item
		.limoking-half-left
		.limoking-item-margin,
	.limoking-service-half-background-item
		.limoking-half-right
		.limoking-item-margin {
		margin: 0px 15px;
	}
	.limoking-service-half-background-item .limoking-half-left .half-container,
	.limoking-service-half-background-item .limoking-half-right .half-container {
		max-width: 420px;
		margin: 0px auto;
	}

	/*--- header ---*/
	body .top-navigation-left {
		float: none;
		text-align: center;
		padding: 3px 0px;
	}
	body .top-navigation-right {
		float: none;
		text-align: center;
		padding: 3px 0px;
	}
	body .limoking-logo {
		padding-right: 60px;
		float: none;
	}
	body .limoking-logo-inner {
		margin-left: 0px;
	}
	body .limoking-logo-container {
		padding-top: 1px;
	}

	#limoking-responsive-navigation {
		display: block;
		height: 0px;
		position: static;
		float: none;
	}
	#limoking-responsive-navigation .dl-trigger {
		position: absolute;
		right: 15px;
		top: 25px;
		margin-top: 0px;
	}
	.dl-menuwrapper > .dl-menu,
	.dl-menuwrapper > .dl-submenu {
		margin: 20px 0px 0px;
		width: 390px;
		text-align: left;
	}

	.limoking-navigation-wrapper {
		display: none;
	}

	body .limoking-logo {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	body .limoking-header-container {
		padding-top: 1px;
	}
	.header-style-2-wrapper .limoking-logo-right-text,
	.header-style-3-wrapper .limoking-logo-right-text,
	.header-style-5-wrapper .limoking-logo-right-text {
		float: none;
		position: static;
		text-align: center;
		margin-top: 0px;
		top: auto;
		padding-bottom: 30px;
		padding-top: 10px;
		margin-right: 0px;
	}

	/*--- page item ---*/
	.limoking-item-title-head .limoking-nav-title {
		display: none;
	}

	.limoking-title-left .about-us-title-wrapper {
		width: auto;
		float: none;
		text-align: center;
	}
	.limoking-title-left .about-us-title-divider {
		margin-right: auto;
		margin-bottom: 26px;
	}

	.limoking-list-menu {
		padding: 0px;
	}

	.limoking-stunning-text-item {
		text-align: center;
	}
	.limoking-stunning-text-item.type-normal.limoking-button-on {
		padding-right: 0px;
	}
	.limoking-stunning-text-item.type-normal .stunning-text-button {
		position: static;
		margin-top: 0px;
	}
	.limoking-stunning-text-item.with-padding.limoking-button-on {
		padding-right: 35px;
	}

	.limoking-stunning-item .stunning-item-content {
		display: block;
		padding: 35px 0px 30px;
	}
	.limoking-stunning-item .stunning-item-button {
		display: block;
		padding: 10px 15px;
		margin-bottom: 10px;
	}
	.limoking-stunning-item.limoking-item {
		display: block;
		width: auto;
	}
	.limoking-stunning-item .stunning-item-title {
		display: block;
		margin-bottom: 12px;
	}
	.limoking-stunning-item .stunning-item-caption {
		display: block;
	}

	.limoking-service-with-image-item .service-with-image-thumbnail {
		float: none;
		margin: 0px auto;
	}
	.limoking-service-with-image-item .service-with-image-content-wrapper {
		text-align: center;
	}

	.limoking-feature-media-item.limoking-left .feature-media-thumbnail {
		float: none;
		width: auto;
		margin-right: 0px;
	}
	.limoking-feature-media-item .feature-media-icon {
		display: none;
	}
	html .woocommerce ul.products li.product,
	html .woocommerce-page ul.products li.product {
		width: 99% !important;
	}

	.limoking-rate-table-wrapper {
		display: none;
	}
	.limoking-rate-table-mobile-wrapper {
		display: block;
	}

	/*--- single page ---*/
	.limoking-portfolio-style1 .limoking-portfolio-description {
		float: none;
		margin-right: 0px;
		width: auto;
	}
	.limoking-portfolio-style1 .limoking-portfolio-info {
		width: auto;
		float: none;
		margin-bottom: 30px;
	}
	.limoking-portfolio-style2 .limoking-portfolio-thumbnail {
		max-width: 100%;
		float: none;
		margin-right: 0px;
		margin-left: 0px;
		width: auto !important;
	}

	.limoking-blog-medium .limoking-blog-thumbnail {
		margin-right: 0px;
		float: none;
		margin-bottom: 20px;
		width: auto;
	}

	.single-car .limoking-car-info-wrapper {
		float: none;
		width: auto;
		margin-left: 0px;
	}

	/*--- slider ---*/
	.limoking-caption-wrapper .limoking-caption .limoking-caption-text {
		display: none;
	}
	.limoking-caption-wrapper .limoking-caption .limoking-caption-title {
		margin-bottom: 0px;
		font-size: 15px;
	}
	.limoking-caption-wrapper .limoking-caption {
		max-width: 100%;
		padding: 13px 12px;
	}

	.limoking-item-title .limoking-flex-prev {
		margin: 0px;
		position: absolute;
		left: 0px;
		top: 50%;
		margin-top: -20px;
	}
	.limoking-item-title .limoking-flex-next {
		margin: 0px;
		position: absolute;
		right: 0px;
		top: 50%;
		margin-top: -20px;
	}
}

/*--- mobile portrait style ---*/
@media only screen and (max-width: 419px) {
	body .container {
		max-width: 300px;
	}
	body .body-wrapper.limoking-boxed-style {
		max-width: 340px;
	}

	.gdlr-service-half-background-item .gdlr-half-left .half-container,
	.gdlr-service-half-background-item .gdlr-half-right .half-container {
		max-width: 300px;
	}

	.dl-menuwrapper .dl-menu,
	.dl-menuwrapper > .dl-submenu {
		width: 270px;
	}

	.limoking-classic-car .car-rate-info-amount {
		float: none;
		display: block;
		margin-bottom: 20px;
	}
}
