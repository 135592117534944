/* Hide default list styles */
.FleetSlider{
    display: flex !important;
    justify-content: center;
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    list-style: none !important;
    text-align: center !important;
}
.FleetSlider .slick-dots {
    list-style: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  
  /* Style the dots */
  .FleetSlider .slick-dots li {
    display: inline-block !important;
  }
  
  .FleetSlider li button {
    background-color: gray !important;
    border: 1px solid white !important;
    width: 10px !important;
    height: 10px !important;
    margin: 0 5px !important;
    border-radius: 50% !important;
    font-size: 0px !important;
  }
  
  /* Style the active dot */
  .FleetSlider  li button:before {
	color: gray !important;
	border: 2px solid white;
	font-size: 1px !important;
	border-radius: 100%;
	background: gray;
	width: 16px !important;
	height: 16px !important;
  }
  
  .FleetSlider  li.slick-active button:before {
    background-color: black !important;
  }
  .FleetSlider  li::marker{
    content: none !important;
  }
  .FleetSlider ul{
    list-style: none !important;
  }