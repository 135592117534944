body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
/* .slick-next {
	color: white !important;
	right: 30px !important;
	z-index: 100;
}
.slick-prev {
	color: white !important;
	left: 30px !important;
	z-index: 100;
} */
.menu-btn {
	width: 30px;
	height: 30px;
	position: relative;
	cursor: pointer;
	background-color: black;
}

.line {
	width: 100%;
	height: 3px;
	background-color: white;
	position: absolute;
	left: 0;
}

.line:nth-child(1) {
	top: 0;
}

.line:nth-child(2) {
	top: 50%;
	transform: translateY(-50%);
}

.line:nth-child(3) {
	bottom: 0;
}

/* .slick-dots {
	bottom: 30px !important; 
}

.slick-dots li button:before {
	color: gray !important;
	border: 2px solid white;
	font-size: 1px !important;
	border-radius: 100%;
	background: gray;
	width: 16px !important;
	height: 16px !important;
} */
/* .hidden {
  display: none;
} */

#modal {
	position: fixed;
	top: 10%;
	left: 0;
	z-index: 100000;

	width: 100%;
	height: 100%;
	z-index: 10; /* Ensure modal is above other content */
}

.modal-content {
	background-color: #303030;
	margin: 15% auto;
	padding: 20px;
	/* border: 1px solid #ccc; */
	width: 80%;
	max-width: 500px; /* Optional: Set a maximum width for responsiveness */
}

.modal-content h2 {
	margin-top: 0;
}

#close-modal {
	padding: 5px 10px;
	background-color: #ccc;
	border: none;
	cursor: pointer;
}
/* .slick-slide img {
	width: 100% !important;
} */
/* Modal Overlay */
.custom-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Modal */
.custom-modal {
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Box shadow for depth */
	padding: 20px;
	max-width: 90%;
	width: 100%;
}

/* Modal Header */
.custom-modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

/* Close Button */
.custom-close-button {
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
	font-size: 20px;
	color: #999;
}

.custom-close-button:hover {
	color: #333;
}

/* Modal Content */
.custom-modal-content {
	/* Add your content styles here */
}

/* Modal Title */
.custom-modal-header h2 {
	margin: 0;
	font-size: 24px;
}

.bookingMain {
	text-align: center;
	margin-top: 35px;
	padding: 25px 20px 0;
	overflow: hidden;
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
	margin: 0;
	margin-bottom: 10px;
	padding-top: 30px;
	border-radius: 0;
}

.enhanced-content-header {
	width: 100%;
}
.promo-wrapper .enhanced-content-header__section {
	margin: 0 auto 15px;
	line-height: 1.4rem;
}
@media only screen and (min-width: 1024px) {
	.enhanced-content-header__section {
		max-width: 368px;
	}
}
@media (max-width: 767px) {
	.enhanced-rich-text {
		max-width: 100% !important;
	}
	.custom-video-background video {
		height: 250px;
	}
	.contactUsImage {
		width: 100% !important;
	}
}
.promo-wrapper .enhanced-content-header__subtext {
	max-width: 600px;
	margin: 0 auto 25px;
}
.promo-wrapper .enhanced-content-header__subtext {
	max-width: 280px;
	margin: 20px auto;
	padding: 0;
	font-weight: 300;
	font-size: 1.4rem;
	line-height: 1.8rem;
}
.enhanced-rich-text {
	max-width: 65%;
	/* margin: 20px auto; */
	padding: 0;
	color: #333;
	margin-bottom: 14px;
	font-weight: 300;
	font-size: 1.4rem;
	line-height:1.5rem;
	text-align: center;
	padding: 0;
	font-weight: 300;
	font-size: 1.4rem;
}
.widthForm {
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
}
.enhanced-content-header__text {
	max-width: 600px;
	margin: 0 auto 25px;
	font-size: 2.4rem;
	line-height: 4.4rem;
	margin: 0 auto 20px;
	padding: 0;
	color: #333;
	font-weight: 600;
	font-size: 2.4rem;
	line-height: 2.8rem;
	text-align: center;
	text-shadow: 2px 2px hsla(0, 0%, 100%, 0.3);
}
.mainFormName {
	margin: 0 auto 15px;
	line-height: 1.4rem;
	font-weight: 700;
	display: block;
	margin: 0 auto 15px;
	padding: 0;
	color: #666;
	font-size: 1rem;
	line-height: 1.4rem;
	letter-spacing: 4px;
	text-align: center;
	text-transform: uppercase;
}
.btn-primary {
	background-color: #d59d23 !important;
}
.custom-video-background {
	position: relative;
	width: 100%;
	max-height: 609px; /* Maximum height for the video */
	overflow: hidden;
}

.custom-video-background video {
	width: 100%;
	height: auto;
	object-fit: cover;
}

.custom-content {
	position: absolute;
	top: 50%;
	width: 100%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: white;
	z-index: 1; /* Ensure content is above the video */
}

.custom-content h1 {
	font-size: 3rem;
}

.custom-content button {
	padding: 10px 20px;
	font-size: 1.5rem;
	background-color: transparent;
	border: 2px solid white;
	color: white;
	cursor: pointer;
	transition: background-color 0.3s, color 0.3s;
}

.custom-content button:hover {
	background-color: white;
	color: black;
}

.video-section {
	position: relative;
}
.Content-Top {
	display: flex;
	flex-direction: column;
	gap: 18px;
	align-items: center;
}

.sf-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3%;
    margin-left: 0;
    list-style: none;
}

.sf-menu li {
    font-weight: 700;
    position: relative;
}

.sf-menu li a {
    text-decoration: none; /* Remove underline from links */
}

.sf-menu li::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0; /* Initial position set to left */
    width: 0;
    height: 3px;
    background-color: transparent; /* Initial border color */
    transition: width 0.3s ease, left 0.3s ease, background-color 0.3s ease; /* Add transition for width, left, and background-color */
}

.sf-menu li:hover::after {
    width: 100%;
    left: 0;
    background-color: #d59d23; /* Change background color on hover */
}

.sf-menu li {
    padding: 10px;
	cursor: pointer;
}

.contactFormMain {
	max-width: 500px;
	margin: 50px auto;
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.contact-container {
	max-width: 500px;
	margin: 50px auto;
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-container h2 {
	margin-top: 0;
}

.form-group {
	margin-bottom: 15px;
}

.form-group label {
	display: block;
	margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="tel"],
.form-group input[type="email"],
.form-group textarea,
button {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-sizing: border-box;
}

button {
	background-color: white;
	cursor: pointer;
}

.contactUsImage {
	width: 60%;
}
.carousel_content{
	position: relative;
}
.content_text{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: max-content;
    margin: auto;
    height: fit-content;
}