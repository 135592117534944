/* SocialIcon.module.css */

.icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 50%;
  }
  
  .icon:hover {
    background-color: #d59d23;
    border-radius: 50%;
  }
  
  .hover {
    background-color: #d59d23 !important;
    color: #ffffff !important;
    border-radius: 50%;
  }
  