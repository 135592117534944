/*
===============================================================
 # CUSTOM CSS
 - Please do not edit this file. this file is generated by server-side code
 - Every changes here will be overwritten
===============================================================*/

.msp-preset-btn-84 {
	background-color: #d59d23;
	color: #fff;
	padding: 15px 20px;
}
.msp-preset-btn-84:hover {
	background-color: #ca89cc;
	color: #fff;
}
.msp-preset-btn-84:active {
	top: 1px;
}
.msp-preset-btn-85 {
	background-color: #b97ebb;
	color: #fff;
}
.msp-preset-btn-85:hover {
	background-color: #ca89cc;
	color: #fff;
}
.msp-preset-btn-85:active {
	top: 1px;
}
.msp-preset-btn-86 {
	color: #b97ebb;
	border: solid 1px #b97ebb;
}
.msp-preset-btn-86:hover {
	border-color: #ca89cc;
	color: #ca89cc;
}
.msp-preset-btn-86:active {
	top: 1px;
}
.msp-preset-btn-87 {
	background-color: #b97ebb;
	color: #fff;
}
.msp-preset-btn-87:hover {
	background-color: #ca89cc;
	color: #fff;
}
.msp-preset-btn-87:active {
	top: 1px;
}
.msp-preset-btn-88 {
	background-color: #b97ebb;
	color: #fff;
	box-shadow: 0 5px #9a699c;
}
.msp-preset-btn-88:hover {
	background-color: #ca89cc;
	box-shadow: 0 4px #9a699c;
	top: 1px;
	color: #fff;
}
.msp-preset-btn-88:active {
	box-shadow: 0 2px #9a699c;
	top: 3px;
}
.msp-preset-btn-89 {
	background-color: #5472d2;
	color: #fff;
}
.msp-preset-btn-89:hover {
	background-color: #5d7fe9;
	color: #fff;
}
.msp-preset-btn-89:active {
	top: 1px;
}
.msp-preset-btn-90 {
	background-color: #5472d2;
	color: #fff;
}
.msp-preset-btn-90:hover {
	background-color: #5d7fe9;
	color: #fff;
}
.msp-preset-btn-90:active {
	top: 1px;
}
.msp-preset-btn-91 {
	color: #5472d2;
	border: solid 1px #5472d2;
}
.msp-preset-btn-91:hover {
	border-color: #5d7fe9;
	color: #5d7fe9;
}
.msp-preset-btn-91:active {
	top: 1px;
}
.msp-preset-btn-92 {
	background-color: #5472d2;
	color: #fff;
}
.msp-preset-btn-92:hover {
	background-color: #5d7fe9;
	color: #fff;
}
.msp-preset-btn-92:active {
	top: 1px;
}
.msp-preset-btn-93 {
	background-color: #5472d2;
	color: #fff;
	box-shadow: 0 5px #4c68be;
}
.msp-preset-btn-93:hover {
	background-color: #5d7fe9;
	box-shadow: 0 4px #4c68be;
	top: 1px;
	color: #fff;
}
.msp-preset-btn-93:active {
	box-shadow: 0 2px #4c68be;
	top: 3px;
}
.msp-preset-btn-94 {
	background-color: #00c1cf;
	color: #fff;
}
.msp-preset-btn-94:hover {
	background-color: #01d4e4;
	color: #fff;
}
.msp-preset-btn-94:active {
	top: 1px;
}
.msp-preset-btn-95 {
	background-color: #00c1cf;
	color: #fff;
}
.msp-preset-btn-95:hover {
	background-color: #01d4e4;
	color: #fff;
}
.msp-preset-btn-95:active {
	top: 1px;
}
.msp-preset-btn-96 {
	color: #00c1cf;
	border: solid 1px #00c1cf;
}
.msp-preset-btn-96:hover {
	border-color: #01d4e4;
	color: #01d4e4;
}
.msp-preset-btn-96:active {
	top: 1px;
}
.msp-preset-btn-97 {
	background-color: #00c1cf;
	color: #fff;
}
.msp-preset-btn-97:hover {
	background-color: #01d4e4;
	color: #fff;
}
.msp-preset-btn-97:active {
	top: 1px;
}
.msp-preset-btn-98 {
	background-color: #00c1cf;
	color: #fff;
	box-shadow: 0 5px #00afbc;
}
.msp-preset-btn-98:hover {
	background-color: #01d4e4;
	box-shadow: 0 4px #00afbc;
	top: 1px;
	color: #fff;
}
.msp-preset-btn-98:active {
	box-shadow: 0 2px #00afbc;
	top: 3px;
}
.msp-preset-btn-99 {
	background-color: #4cadc9;
	color: #fff;
}
.msp-preset-btn-99:hover {
	background-color: #63b2c9;
	color: #fff;
}
.msp-preset-btn-99:active {
	top: 1px;
}
.msp-preset-btn-100 {
	background-color: #4cadc9;
	color: #fff;
}
.msp-preset-btn-100:hover {
	background-color: #63b2c9;
	color: #fff;
}
.msp-preset-btn-100:active {
	top: 1px;
}
.msp-preset-btn-101 {
	color: #4cadc9;
	border: solid 1px #4cadc9;
}
.msp-preset-btn-101:hover {
	border-color: #63b2c9;
	color: #63b2c9;
}
.msp-preset-btn-101:active {
	top: 1px;
}
.msp-preset-btn-102 {
	background-color: #4cadc9;
	color: #fff;
}
.msp-preset-btn-102:hover {
	background-color: #63b2c9;
	color: #fff;
}
.msp-preset-btn-102:active {
	top: 1px;
}
.msp-preset-btn-103 {
	background-color: #4cadc9;
	color: #fff;
	box-shadow: 0 5px #1aa2c9;
}
.msp-preset-btn-103:hover {
	background-color: #63b2c9;
	box-shadow: 0 4px #1aa2c9;
	top: 1px;
	color: #fff;
}
.msp-preset-btn-103:active {
	box-shadow: 0 2px #1aa2c9;
	top: 3px;
}
.msp-preset-btn-104 {
	background-color: #cec2ab;
	color: #fff;
}
.msp-preset-btn-104:hover {
	background-color: #cebd9d;
	color: #fff;
}
.msp-preset-btn-104:active {
	top: 1px;
}
.msp-preset-btn-105 {
	background-color: #cec2ab;
	color: #fff;
}
.msp-preset-btn-105:hover {
	background-color: #cebd9d;
	color: #fff;
}
.msp-preset-btn-105:active {
	top: 1px;
}
.msp-preset-btn-106 {
	color: #cec2ab;
	border: solid 1px #cec2ab;
}
.msp-preset-btn-106:hover {
	border-color: #cebd9d;
	color: #cebd9d;
}
.msp-preset-btn-106:active {
	top: 1px;
}
.msp-preset-btn-107 {
	background-color: #cec2ab;
	color: #fff;
}
.msp-preset-btn-107:hover {
	background-color: #cebd9d;
	color: #fff;
}
.msp-preset-btn-107:active {
	top: 1px;
}
.msp-preset-btn-108 {
	background-color: #cec2ab;
	color: #fff;
	box-shadow: 0 5px #c2b7a2;
}
.msp-preset-btn-108:hover {
	background-color: #cebd9d;
	box-shadow: 0 4px #c2b7a2;
	top: 1px;
	color: #fff;
}
.msp-preset-btn-108:active {
	box-shadow: 0 2px #c2b7a2;
	top: 3px;
}
.msp-preset-btn-109 {
	background-color: #50485b;
	color: #fff;
}
.msp-preset-btn-109:hover {
	background-color: #6a6176;
	color: #fff;
}
.msp-preset-btn-109:active {
	top: 1px;
}
.msp-preset-btn-110 {
	background-color: #50485b;
	color: #fff;
}
.msp-preset-btn-110:hover {
	background-color: #6a6176;
	color: #fff;
}
.msp-preset-btn-110:active {
	top: 1px;
}
.msp-preset-btn-111 {
	color: #50485b;
	border: solid 1px #50485b;
}
.msp-preset-btn-111:hover {
	border-color: #6a6176;
	color: #6a6176;
}
.msp-preset-btn-111:active {
	top: 1px;
}
.msp-preset-btn-112 {
	background-color: #50485b;
	color: #fff;
}
.msp-preset-btn-112:hover {
	background-color: #6a6176;
	color: #fff;
}
.msp-preset-btn-112:active {
	top: 1px;
}
.msp-preset-btn-113 {
	background-color: #50485b;
	color: #fff;
	box-shadow: 0 5px #412d5b;
}
.msp-preset-btn-113:hover {
	background-color: #6a6176;
	box-shadow: 0 4px #412d5b;
	top: 1px;
	color: #fff;
}
.msp-preset-btn-113:active {
	box-shadow: 0 2px #412d5b;
	top: 3px;
}
.msp-preset-btn-114 {
	background-color: #8d6dc4;
	color: #fff;
}
.msp-preset-btn-114:hover {
	background-color: #977cc4;
	color: #fff;
}
.msp-preset-btn-114:active {
	top: 1px;
}
.msp-preset-btn-115 {
	background-color: #8d6dc4;
	color: #fff;
}
.msp-preset-btn-115:hover {
	background-color: #977cc4;
	color: #fff;
}
.msp-preset-btn-115:active {
	top: 1px;
}
.msp-preset-btn-116 {
	color: #8d6dc4;
	border: solid 1px #8d6dc4;
}
.msp-preset-btn-116:hover {
	border-color: #977cc4;
	color: #977cc4;
}
.msp-preset-btn-116:active {
	top: 1px;
}
.msp-preset-btn-117 {
	background-color: #8d6dc4;
	color: #fff;
}
.msp-preset-btn-117:hover {
	background-color: #977cc4;
	color: #fff;
}
.msp-preset-btn-117:active {
	top: 1px;
}
.msp-preset-btn-118 {
	background-color: #8d6dc4;
	color: #fff;
	box-shadow: 0 5px #7c51c4;
}
.msp-preset-btn-118:hover {
	background-color: #977cc4;
	box-shadow: 0 4px #7c51c4;
	top: 1px;
	color: #fff;
}
.msp-preset-btn-118:active {
	box-shadow: 0 2px #7c51c4;
	top: 3px;
}
.msp-preset-btn-119 {
	background-color: #75d69c;
	color: #fff;
}
.msp-preset-btn-119:hover {
	background-color: #7de5a7;
	color: #fff;
}
.msp-preset-btn-119:active {
	top: 1px;
}
.msp-preset-btn-120 {
	background-color: #75d69c;
	color: #fff;
}
.msp-preset-btn-120:hover {
	background-color: #7de5a7;
	color: #fff;
}
.msp-preset-btn-120:active {
	top: 1px;
}
.msp-preset-btn-121 {
	color: #75d69c;
	border: solid 1px #75d69c;
}
.msp-preset-btn-121:hover {
	border-color: #7de5a7;
	color: #7de5a7;
}
.msp-preset-btn-121:active {
	top: 1px;
}
.msp-preset-btn-122 {
	background-color: #75d69c;
	color: #fff;
}
.msp-preset-btn-122:hover {
	background-color: #7de5a7;
	color: #fff;
}
.msp-preset-btn-122:active {
	top: 1px;
}
.msp-preset-btn-123 {
	background-color: #75d69c;
	color: #fff;
	box-shadow: 0 5px #41d67d;
}
.msp-preset-btn-123:hover {
	background-color: #7de5a7;
	box-shadow: 0 4px #41d67d;
	top: 1px;
	color: #fff;
}
.msp-preset-btn-123:active {
	box-shadow: 0 2px #41d67d;
	top: 3px;
}
.msp-preset-btn-124 {
	background-color: #222;
	color: #fff;
}
.msp-preset-btn-124:hover {
	background-color: #333;
	color: #fff;
}
.msp-preset-btn-124:active {
	top: 1px;
}
.msp-preset-btn-125 {
	background-color: #222;
	color: #fff;
}
.msp-preset-btn-125:hover {
	background-color: #333;
	color: #fff;
}
.msp-preset-btn-125:active {
	top: 1px;
}
.msp-preset-btn-126 {
	color: #222;
	border: solid 1px #222;
}
.msp-preset-btn-126:hover {
	border-color: #333;
	color: #333;
}
.msp-preset-btn-126:active {
	top: 1px;
}
.msp-preset-btn-127 {
	background-color: #222;
	color: #fff;
}
.msp-preset-btn-127:hover {
	background-color: #333;
	color: #fff;
}
.msp-preset-btn-127:active {
	top: 1px;
}
.msp-preset-btn-128 {
	background-color: #222;
	color: #fff;
	box-shadow: 0 5px #000;
}
.msp-preset-btn-128:hover {
	background-color: #333;
	box-shadow: 0 4px #000;
	top: 1px;
	color: #fff;
}
.msp-preset-btn-128:active {
	box-shadow: 0 2px #000;
	top: 3px;
}
.msp-preset-btn-129 {
	background-color: #dedede;
	color: #666;
}
.msp-preset-btn-129:hover {
	background-color: #d1d1d1;
	color: #666;
}
.msp-preset-btn-129:active {
	top: 1px;
}
.msp-preset-btn-130 {
	background-color: #dedede;
	color: #666;
}
.msp-preset-btn-130:hover {
	background-color: #d1d1d1;
	color: #666;
}
.msp-preset-btn-130:active {
	top: 1px;
}
.msp-preset-btn-131 {
	color: #dedede;
	border: solid 1px #dedede;
}
.msp-preset-btn-131:hover {
	border-color: #d1d1d1;
	color: #d1d1d1;
}
.msp-preset-btn-131:active {
	top: 1px;
}
.msp-preset-btn-132 {
	background-color: #dedede;
	color: #666;
}
.msp-preset-btn-132:hover {
	background-color: #d1d1d1;
	color: #666;
}
.msp-preset-btn-132:active {
	top: 1px;
}
.msp-preset-btn-133 {
	background-color: #dedede;
	color: #666;
	box-shadow: 0 5px #cacaca;
}
.msp-preset-btn-133:hover {
	background-color: #d1d1d1;
	color: #666;
	box-shadow: 0 4px #cacaca;
	top: 1px;
}
.msp-preset-btn-133:active {
	box-shadow: 0 2px #cacaca;
	top: 3px;
}
.msp-preset-btn-134 {
	background-color: #f7be68;
	color: #fff;
}
.msp-preset-btn-134:hover {
	background-color: #e9b362;
	color: #fff;
}
.msp-preset-btn-134:active {
	top: 1px;
}
.msp-preset-btn-135 {
	background-color: #f7be68;
	color: #fff;
}
.msp-preset-btn-135:hover {
	background-color: #e9b362;
	color: #fff;
}
.msp-preset-btn-135:active {
	top: 1px;
}
.msp-preset-btn-136 {
	color: #f7be68;
	border: solid 1px #f7be68;
}
.msp-preset-btn-136:hover {
	border-color: #e9b362;
	color: #e9b362;
}
.msp-preset-btn-136:active {
	top: 1px;
}
.msp-preset-btn-137 {
	background-color: #f7be68;
	color: #fff;
}
.msp-preset-btn-137:hover {
	background-color: #e9b362;
	color: #fff;
}
.msp-preset-btn-137:active {
	top: 1px;
}
.msp-preset-btn-138 {
	background-color: #f7be68;
	color: #fff;
	box-shadow: 0 5px #e7af59;
}
.msp-preset-btn-138:hover {
	background-color: #e9b362;
	box-shadow: 0 4px #e7af59;
	top: 1px;
	color: #fff;
}
.msp-preset-btn-138:active {
	box-shadow: 0 2px #e7af59;
	top: 3px;
}
.msp-preset-btn-139 {
	background-color: #5aa1e3;
	color: #fff;
}
.msp-preset-btn-139:hover {
	background-color: #5faaef;
	color: #fff;
}
.msp-preset-btn-139:active {
	top: 1px;
}
.msp-preset-btn-140 {
	background-color: #5aa1e3;
	color: #fff;
}
.msp-preset-btn-140:hover {
	background-color: #5faaef;
	color: #fff;
}
.msp-preset-btn-140:active {
	top: 1px;
}
.msp-preset-btn-141 {
	color: #5aa1e3;
	border: solid 1px #5aa1e3;
}
.msp-preset-btn-141:hover {
	border-color: #5faaef;
	color: #5faaef;
}
.msp-preset-btn-141:active {
	top: 1px;
}
.msp-preset-btn-142 {
	background-color: #5aa1e3;
	color: #fff;
}
.msp-preset-btn-142:hover {
	background-color: #5faaef;
	color: #fff;
}
.msp-preset-btn-142:active {
	top: 1px;
}
.msp-preset-btn-143 {
	background-color: #5aa1e3;
	color: #fff;
	box-shadow: 0 5px #4c87bf;
}
.msp-preset-btn-143:hover {
	background-color: #5faaef;
	box-shadow: 0 4px #4c87bf;
	top: 1px;
	color: #fff;
}
.msp-preset-btn-143:active {
	box-shadow: 0 2px #4c87bf;
	top: 3px;
}
.msp-preset-btn-144 {
	background-color: #6dab3c;
	color: #fff;
}
.msp-preset-btn-144:hover {
	background-color: #76b941;
	color: #fff;
}
.msp-preset-btn-144:active {
	top: 1px;
}
.msp-preset-btn-145 {
	background-color: #6dab3c;
	color: #fff;
}
.msp-preset-btn-145:hover {
	background-color: #76b941;
	color: #fff;
}
.msp-preset-btn-145:active {
	top: 1px;
}
.msp-preset-btn-146 {
	color: #6dab3c;
	border: solid 1px #6dab3c;
}
.msp-preset-btn-146:hover {
	border-color: #76b941;
	color: #76b941;
}
.msp-preset-btn-146:active {
	top: 1px;
}
.msp-preset-btn-147 {
	background-color: #6dab3c;
	color: #fff;
}
.msp-preset-btn-147:hover {
	background-color: #76b941;
	color: #fff;
}
.msp-preset-btn-147:active {
	top: 1px;
}
.msp-preset-btn-148 {
	background-color: #6dab3c;
	color: #fff;
	box-shadow: 0 5px #5e9334;
}
.msp-preset-btn-148:hover {
	background-color: #76b941;
	box-shadow: 0 4px #5e9334;
	top: 1px;
	color: #fff;
}
.msp-preset-btn-148:active {
	box-shadow: 0 2px #5e9334;
	top: 3px;
}
.msp-preset-btn-149 {
	background-color: #f4524d;
	color: #fff;
}
.msp-preset-btn-149:hover {
	background-color: #e04b47;
	color: #fff;
}
.msp-preset-btn-149:active {
	top: 1px;
}
.msp-preset-btn-150 {
	background-color: #f4524d;
	color: #fff;
}
.msp-preset-btn-150:hover {
	background-color: #e04b47;
	color: #fff;
}
.msp-preset-btn-150:active {
	top: 1px;
}
.msp-preset-btn-151 {
	color: #f4524d;
	border: solid 1px #f4524d;
}
.msp-preset-btn-151:hover {
	border-color: #e04b47;
	color: #e04b47;
}
.msp-preset-btn-151:active {
	top: 1px;
}
.msp-preset-btn-152 {
	background-color: #f4524d;
	color: #fff;
}
.msp-preset-btn-152:hover {
	background-color: #e04b47;
	color: #fff;
}
.msp-preset-btn-152:active {
	top: 1px;
}
.msp-preset-btn-153 {
	background-color: #f4524d;
	color: #fff;
	box-shadow: 0 5px #cb4440;
}
.msp-preset-btn-153:hover {
	background-color: #e04b47;
	box-shadow: 0 4px #cb4440;
	top: 1px;
	color: #fff;
}
.msp-preset-btn-153:active {
	box-shadow: 0 2px #cb4440;
	top: 3px;
}
.msp-preset-btn-154 {
	background-color: #f79468;
	color: #fff;
}
.msp-preset-btn-154:hover {
	background-color: #e78a61;
	color: #fff;
}
.msp-preset-btn-154:active {
	top: 1px;
}
.msp-preset-btn-155 {
	background-color: #f79468;
	color: #fff;
}
.msp-preset-btn-155:hover {
	background-color: #e78a61;
	color: #fff;
}
.msp-preset-btn-155:active {
	top: 1px;
}
.msp-preset-btn-156 {
	color: #f79468;
	border: solid 1px #f79468;
}
.msp-preset-btn-156:hover {
	border-color: #e78a61;
	color: #e78a61;
}
.msp-preset-btn-156:active {
	top: 1px;
}
.msp-preset-btn-157 {
	background-color: #f79468;
	color: #fff;
}
.msp-preset-btn-157:hover {
	background-color: #e78a61;
	color: #fff;
}
.msp-preset-btn-157:active {
	top: 1px;
}
.msp-preset-btn-158 {
	background-color: #f79468;
	color: #fff;
	box-shadow: 0 5px #da835c;
}
.msp-preset-btn-158:hover {
	background-color: #e78a61;
	box-shadow: 0 4px #da835c;
	top: 1px;
	color: #fff;
}
.msp-preset-btn-158:active {
	box-shadow: 0 2px #da835c;
	top: 3px;
}
.msp-preset-btn-159 {
	background-color: #d59d23;
	color: #fff;
	padding: 15px 23px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
.msp-preset-btn-159:hover {
	background-color: #d59d23;
	color: #fff;
	padding: 15px 23px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
.msp-preset-btn-159:active {
	background-color: #d59d23;
	color: #fff;
	padding: 15px 23px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
.msp-cn-1-1 {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 44px;
	letter-spacing: 2px;
	line-height: normal;
	color: #ffffff;
	text-transform: uppercase;
}
.msp-cn-1-2 {
	font-family: "Hind";
	font-weight: normal;
	font-size: 18px;
	text-align: center;
	line-height: normal;
	color: #ffffff;
}
.msp-cn-1-3 {
	font-family: "Montserrat";
	font-weight: normal;
	font-size: 30px;
	letter-spacing: 2px;
	line-height: normal;
	color: #ffffff;
	text-transform: uppercase;
}
.msp-cn-1-6 {
	font-family: "Hind";
	font-weight: 300;
	font-size: 29px;
	line-height: normal;
	color: #d59d23;
	font-style: italic;
}
.msp-cn-2-7 {
	font-family: "Merriweather";
	font-weight: 700;
	font-size: 14px;
	line-height: normal;
	color: #ffffff;
	font-style: italic;
}

.msp-cn-2-1 {
	font-family: "Raleway";
	font-weight: 700;
	font-size: 59px;
	line-height: normal;
	color: #d59d23;
}
.msp-cn-2-2 {
	font-family: "Raleway";
	font-weight: normal;
	font-size: 40px;
	line-height: normal;
	color: #a6dde8;
}
.msp-cn-2-3 {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 111px;
	letter-spacing: 3px;
	line-height: normal;
	color: #ffffff;
	text-transform: uppercase;
}
.msp-cn-2-9 {
	font-family: "Merriweather";
	font-weight: normal;
	font-size: 17px;
	line-height: normal;
	color: #ffffff;
	font-style: italic;
}
.msp-cn-2-10 {
	font-family: "Raleway";
	font-weight: 800;
	font-size: 53px;
	letter-spacing: 1px;
	line-height: normal;
	color: #ffffff;
}
.msp-cn-2-11 {
	font-family: "Hind";
	font-weight: normal;
	font-size: 15px;
	line-height: normal;
	color: #dddddd;
}
.msp-cn-1-12 {
	font-family: "Merriweather";
	font-weight: 700;
	font-size: 14px;
	line-height: normal;
	color: #ffffff;
	font-style: italic;
}
.msp-cn-2-13 {
	font-family: "Raleway";
	font-weight: normal;
	font-size: 100px;
	line-height: normal;
	color: #ffffff;
}
.msp-cn-2-14 {
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 58px;
	line-height: normal;
	color: #d59d23;
}
@media (max-width: 767px) { 
    .msp-cn-1-1{
        margin: 0px;
        padding: 0px;
        font-size: 15.4373px;
    } 
	.msp-cn-2-1{
		text-align: center;
		font-size: 20.7px;
	}
	.msp-cn-2-2{
		text-align: center;
		font-size: 14.0339px;
	}
	.msp-cn-2-3{
		text-align: center;
		font-size: 38.9441px;
		
	}
	.msp-cn-2-10{
		font-size: 18.5949px;
	}
	.msp-cn-2-11{
		font-size: 15px;
	}
	.msp-cn-1-12{
		font-size: 14px;
	}
	.msp-cn-2-13{
		font-size: 35.0847px;
		text-align: center;
	}
	.msp-cn-2-14{
		font-size: 20px;
	}
    .msp-cn-1-2{
        margin: 0px;
        padding: 0px;
        font-size: 10.31525px !important;
        top: 85.8258px;
        left: 119.319px;
    }
    .msp-cn-1-3{
        margin: 0px;
        padding: 0px;
        font-size: 10.5254px;
        top: 117.795px;
        left: 110.487px;
    }
    .msp-cn-1-6{
        margin: 0px;
        padding: 0px;
        font-size: 10.1746px;
        top: 141.106px;
        left: 200.787px;
    }
    .ms-btn-container{
        top: 168.009px;
        left: 184.899px;
        width: 44.2015px;
        height: 16.9254px;
    }
}